<template>
    <el-container>
      <el-main>
         <el-row type="flex" justify="center">
            <el-image :src="require('@/assets/logo.svg')" class="logo"></el-image>
         </el-row>
         <el-row :gutter="12" type="flex" justify="center">
            <el-col :span="6" class="form">
               <el-card class="box-card">
                  <template #header>
                     <div class="card-header">
                        <span>Восстановление пароля</span>
                     </div>
                  </template>
                  <el-form :model="resetPassForm" :rules="rules" ref="resetPassForm">
                        <el-form-item prop="password">
                              <el-input
                                 type="password"
                                 v-model.trim="resetPassForm.password"
                                 placeholder="Пароль"
                              ></el-input>
                        </el-form-item>
                        <el-form-item prop="password_confirm">
                              <el-input
                                 type="password"
                                 v-model.trim="resetPassForm.password_confirm"
                                 placeholder="Подтвердите пароль"
                              ></el-input>
                        </el-form-item>
                        <el-form-item>
                              <el-button @click="resetPass()">Отправить</el-button>
                        </el-form-item>
                  </el-form>
               </el-card>
            </el-col>
         </el-row>
      </el-main>
   </el-container>
</template>
<script>

import Axios from 'axios';
import { ElMessage } from 'element-plus';
import { mapActions, mapMutations } from 'vuex';
// import {
//     LOADING_SPINNER_SHOW_MUTATION,
//     LOGIN_ACTION,
// } from '../store/storeconstants';
export default {
    data() {
       let validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Пожалуйста введите пароль'));
        } else {
          if (this.resetPassForm.password_confirm !== '') {
            this.$refs.resetPassForm.validateField('password_confirm');
          }
          callback();
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Пожалуйста введите пароль ещё раз'));
        } else if (value !== this.resetPassForm.password) {
          callback(new Error('Пароли не совпадают!'));
        } else {
          callback();
        }
      };
        return {
            errors: [],
            error: '',
            resetPassForm: {
               password: '',
               password_confirm: '',
            },
            rules: {
               password: [
                  { validator: validatePass, trigger: 'blur' }
               ],
               password_confirm: [
                  { validator: validatePass2, trigger: 'blur' }
               ]
         }
        };
    },
    methods: {
        ...mapActions('auth', {
            // login: LOGIN_ACTION,
        }),
        ...mapMutations({
            // showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        async resetPass() {
           try {
            await Axios.post(`https://betterlife-school.ru/app/auth/reset`, {
               password: this.resetPassForm.password,
               password_confirm: this.resetPassForm.password_confirm,
               token: this.$route.params.token
            })

            await ElMessage({
               type: "success",
               message: "Ваш новый пароль устанволен",
            })

            this.$router.push('/login');

           } catch (error) {
              this.error = error;
                  ElMessage({
                     type: 'warning',
                     message: `${this.error}`,
                  });
           }

        },
    },
};
</script>

<style>
   .logo {
      margin: 15px 0px 35px;
   }
</style>

<style scoped>
   .el-container {
         background: #5E597B;
         height: 100%;
      }
   .el-button {
      width: 100%;
   }
   a {
      text-decoration: none;
   }
   .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
</style>
